import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from '@reach/router';

const RaxSuite = () => {
  
  return (
    <>
      <header className="rax-suite-hero-imgs">
        <Carousel indicators={ false }>
          <Carousel.Item className="carousel-two carousel-bg-1">
            <Carousel.Caption>
              <h1 className="text-white font-weight-bold">RAX Suite is driven</h1>
              <p className="text-white">by two powerful software</p>
              <div className="row">
                <div className="offset-md-2 col-md-4 col-sm-12">
                  <h3 className="h3">RAX MONITOR</h3>
                </div>
                <div className="col-md-4 col-sm-12">
                  <h3 className="h3">RAX EDITOR</h3>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carousel-two carousel-bg-2">
            <Carousel.Caption>
              <h3 className="text-white">RAX MONITOR</h3>
              <p className="text-white">The productivity tracking application, follows and logs your people’s ongoing desktop footprint in detail.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carousel-two carousel-bg-3">
            <Carousel.Caption>
              <h3>RAX EDITOR</h3>
              <p className="text-white">The automation software, allows you to create customized automation of any degree of complexity</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </header>

      <div className="container-fluid rax-suite-container">
        <div className="container mt-5 mb-5 pt-5 pb-5">
          <div className="row">
            <div className="my-auto mx-auto">
              <div className="text-center mx-auto">
                <h2 className="h2">The state of the art end-to-end solution</h2>
                <h5 className="my-4 mx-auto text-center font-weight-normal mb-5 container-80 rax-head" style={{ textTransform: 'none!important' }}>RAX Automation Suite covers extensive productivity insighting and leads to effective automation of repetitive tasks.</h5>
                <h5 className="my-4 mx-auto text-center font-weight-normal pb-5 container-80 rax-head" style={{ textTransform: 'none!important' }}>Most importantly, providing you quantifiable information about the work efficiency gained through automation.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5 mb-5 pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="d-flex parent flex-wrap">
              <div className="col-lg-6 col-md-12 col-sm-12 my-auto text-left">
                <div id="rax-monitor-container-link"></div>
                <h5 className="sm-title text-left rax-head">RAX</h5>
                <h1 className="my-2 mb-3 font-weight-bold" id="rax-monitor-container-link">MONITOR</h1>
                <h1 className="rax-head sr-only sr-only-focusable">RAX MONITOR</h1>
                <p>The productivity tracking application, follows and logs your people’s ongoing desktop footprint in detail.</p>
                <p>It analyzes these desktop activities and interactions using artificial intelligence that, recognizes workflow patterns, and provides insights to RAX Studio’s automation.</p>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 my-auto mx-auto pb-5">
                <iframe title="Manage your staff working from home with RAX MONITOR" width="100%" height="315" src="https://www.youtube.com/embed/2r2D3IsrnFQ" allowFullScreen loading="lazy"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-100 pb-100 gray-bg">
        <div className="row">
          <div className="col-xl-6 mx-auto text-center">
            <div className="section-title">
              <h5 className="rax-head mb-3">Get an intuitive dashboard that allows you to:</h5>
            </div>
            <div className="mx-auto text-center">
              <img className="img-fluid rounded img-90 mb-5" src="imgs/rax-monitor.png" alt="RAX MONITOR" />
            </div>
          </div>
        </div>

        <div className="row rax-suite-allows-you-list col-sm-8 offset-sm-2 ml-auto mr-auto">
          <ul className="list-unstyled">
            <li><i className="fas fa-check mr-3"></i> Obtain real-time visibility of your people’s desktop activities</li>
            <li><i className="fas fa-check mr-3"></i> Extract meaningful reports</li>
            <li><i className="fas fa-check mr-3"></i> Acquire accurate data on your organization’s productivity and utilization</li>
            <li><i className="fas fa-check mr-3"></i> Discover automatable work procedures</li>
            <li><i className="fas fa-check mr-3"></i> Feed into the automation process</li>
          </ul>
        </div>
      </div>

      <section className="services-area pt-100 pb-70" id="services">
        <div className="container">
            <div className="text-center mb-5">
              <h4 className="text-uppercase">Features</h4>
              <hr className="divider my-4" />
            </div>

          <div className="row">
            <div className="col-md-6 col-sm-12">
              <ul className="list-unstyled">
                <li className="media">
                  <div className="icon-features-div">
                    <i className="fas fa-cogs features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Auto Run</h5>
                    <p>Runs automatically on desktop startup and logs your people’s activities at the beginning of their work day.</p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-file-alt features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Work Summary</h5>
                    <p>Provides a detailed summary of the users’ work day, including total time spent per status, tasks, and activities.</p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-list-alt features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Task Monitoring</h5>
                    <p>Allows your people to set their work status and current tasks under it in real-time. </p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-bell features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Breaks Alert</h5>
                    <p>Informs managers when a team member exceeds the defined threshold for breaks. </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-12">
              <ul className="list-unstyled">
                <li className="media">
                  <div className="icon-features-div">
                    <i className="fas fa-user-cog features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Simple Administration</h5>
                    <p>Allows your administrators to easily manage users, roles, departments, status, and activities through the Administrator section. </p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-clock features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Real-Time In-Office Hours</h5>
                    <p>Shows in real time users’ actual time spent in the office. </p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-edit features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Customizable Tasks</h5>
                    <p>Enables your admins and managers to define tasks and activities specific to different user roles. </p>
                  </div>
                </li>
                  <li className="media my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-chart-pie features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Visual Reporting</h5>
                    <p>Provides managers and administrators a view of the detailed visual reports about their team members' activities in the Reports dashboard. </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid rax-suite-cta-container mt-5 pt-5 pb-5">
        <div className="text-center mt-5 mb-5 pt-5 pb-5 text-white">
        <h3 className="h3 pb-3">We are here to help you with your business needs</h3>
          <p>
            <Link className="btn btn-primary mb-2" to="/contact" role="button">Contact Us</Link>
            &nbsp;
            <Link className="btn btn-primary mb-2" to="/freedownload" role="button">Download Free Version</Link>
          </p>
        </div>
      </div>

      <div className="container-fluid mt-5 mb-5 pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 mx-auto my-auto">
              <img className="img-fluid rounded img-90 mb-5" src="imgs/rax-editor.jpg" alt="RAX EDITOR" />
            </div>
            <div className="col-md-6 col-sm-12 my-auto text-left">
              <h5 className="sm-title text-left rax-head">RAX</h5>
              <h1 className="my-2 mb-3 font-weight-bold">EDITOR</h1>
              <p>The automation software, allows you to create customized automation of any degree of complexity.</p>
              <p>Its virtual workforce can handle tasks efficiently and flawlessly across different applications and environments through assisted automation <span className="font-italic">(interaction between your people and its virtual agents is required) or unassisted automation (no human control is needed).</span></p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container-fluid pt-100 pb-100 gray-bg">
        <div className="row">
          <div className="col-xl-8 mx-auto text-center">
            <div className="section-title">
              <h5 className="rax-head">Get an adaptable automation tool that allows you to:</h5>
            </div>
          </div>
        </div>

        <div className="row rax-suite-allows-you-list container-60 ml-auto mr-auto">
          <ul className="list-unstyled">
            <li><i className="fas fa-check mr-3"></i> Drag-and-drop activities to the workflow area for easy automation experience </li>
            <li><i className="fas fa-check mr-3"></i> Tailor automation to your requirement </li>
            <li><i className="fas fa-check mr-3"></i> Leave the mundane and repetitive tasks to virtual agents </li>
            <li><i className="fas fa-check mr-3"></i> Engage your people in meaningful and high-value tasks </li>
            <li><i className="fas fa-check mr-3"></i> Create automation for error-free transactions and better overall output </li>
          </ul>
        </div>
      </div>

      <section className="services-area pt-100 pb-70 mb-5" id="services">
        <div className="container">
          <div className="text-center mb-5">
            <h4 className="text-uppercase">Features</h4>
            <hr className="divider my-4" />
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12">
              <ul className="list-unstyled">
                <li className="media">
                  <div className="icon-features-div">
                    <i className="fas fa-atom features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Automation Toolbox</h5>
                    <p>Allows easy search and use of tools from an assortment of comprehensive automation activities in creating your workflows.</p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <img src="imgs/new-window.svg" className="icon-flaticon features-icon mr-3" alt="New Window" />
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Web Data Scraping</h5>
                    <p>Makes accessing and harvesting of data across multiple web pages easily and accurately. </p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-video features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Desktop Recording</h5>
                    <p>Records desktop actions while users are at work, which can be fed into the creation of automation workflows that can be customized further using the Automation Toolbox. </p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-bug features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Error Handling</h5>
                    <p>Intuitively shows the issues encountered with specific activities during real-time debugging throughout the workflow creation. </p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-hands-helping features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Assisted &amp; Unassisted Automation</h5>
                    <p>Executes sequences by itself or waits for a go signal from the user before executing the sequence. </p>
                  </div>
                </li>
                <li className="media">
                  <div className="icon-features-div">
                    <i className="fas fa-network-wired features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Flexible Workflows</h5>
                    <p>Creates sequences, flowcharts, or a combination of both, suited for your business operations. </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-12">
              <ul className="list-unstyled">
                <li className="media">
                  <div className="icon-features-div">
                    <i className="fas fa-eye features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Visual Drag &amp; Drop Interface</h5>
                    <p>Allows dragging of activities from the toolbox and dropping them to the project workflow area during workflow creation. </p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-toolbox features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Toolbox Customization</h5>
                    <p>Allows the inclusion of niche toolbox activities in the default toolbox from the toolbox compendium. </p>
                  </div>
                </li>
                <li className="media my-4">
                  <div className="icon-features-div">
                    <img src="imgs/sequential-flow.svg" className="img-fluid icon-flaticon features-icon mr-3" alt="Sequential Flow" />
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Workflow Outline</h5>
                    <p>Provides a sequential overview of all the activities used in the workflow at a glance. </p>
                  </div>
                </li>
                <li className="media media-cross-platform-integration my-4">
                  <div className="icon-features-div">
                    <i className="fas fa-crosshairs features-icon mr-3"></i>
                  </div>
                  <div className="media-body">
                    <h5 className="mt-0 mb-1 h5-features">Cross-platform Integration</h5>
                    <p>Does expert handling of workflows across multiple Windows applications: </p>
                    <p className="pl-4">Desktop Applications | Spreadsheet Applications (Excel) | Email Integration | And more...</p>
                    <h2 className="h4 text-left font-gray">Websites</h2>
                    <p>E-commerce, Airline Booking, Email Integration, and more...</p>
                    <h2 className="h4 text-left font-gray">Environments</h2>
                    <p>Mainframe, Windows, and more...</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
    
  );
};

export default RaxSuite;