import React, { useState, useRef } from 'react';
import ContactForm from 'components/ContactForm/ContactForm';
import ContactFormSuccess from 'components/ContactFormSuccess/ContactFormSuccess';
import ContactFormError from 'components/ContactFormError/ContactFormError';

const Contact = () => {
  const formElt = useRef(null);

  const [ formStatus, setFormStatus ] = useState('');
  const onFormSuccess = () => {
    setFormStatus('success');
    scrollToForm();
  };

  const onFormError = (error) => {
    console.error(error);
    setFormStatus('error');
    scrollToForm();
  };

  const scrollToForm = () => {
    const y = formElt.current.getBoundingClientRect().top + window.pageYOffset - 250;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <>
      <header>
        <div className="jumbotron jumbotron-fluid contact-hero-img my-auto-substitute">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h4 className="font-weight-normal h4-hero h4-style">Contact Us</h4>
                <h1 className="text-white font-weight-bold h1-hero">How can we help you?</h1>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div  ref={ formElt }>
      <div className="container mt-5 mb-5 pt-5 pb-5">
        <div className="row">
          <div className="col-md-7 col-sm-12 mb-5">
            {
              formStatus !== 'success' ?
                formStatus !== 'error' ?
                  <ContactForm 
                    onSuccess={ onFormSuccess }
                    onError={ onFormError }>
                  </ContactForm> :
                <ContactFormError /> :
                <ContactFormSuccess />
            }
          </div>

          <div className="col-md-5 col-sm-12">
            <h4 className="mb-3">Contact Details</h4>
            <div className="media media-cross-platform-integration">
              <img src="https://image.flaticon.com/icons/svg/197/197604.svg" className="align-self-start mr-3 flag-icon img-fluid" alt="Japanese Flag" />
              <div className="media-body">
                <div className="bd-example">
                  <address className="contact-address">
                    <strong className="text-uppercase">Japan</strong><br />
                    1-1-39, Hiroo<br />
                    Ebisu Prime Square Tower, 4F.<br />
                    Shibuya-ku, Tokyo, 150-0012 Japan<br />
                    <abbr title="Phone">Phone:</abbr>+81 344 557 243
                  </address>
                </div>
              </div>
            </div>

            <div className="media media-cross-platform-integration">
              <img src="https://image.flaticon.com/icons/svg/197/197561.svg" className="align-self-start mr-3 flag-icon img-fluid" alt="Philippine Flag" />
              <div className="media-body">
                <div className="bd-example">
                  <address className="contact-address">
                    <strong className="text-uppercase">Philippines</strong><br />
                    Unit 200A 2/F Weremote, Building C<br />
                    Metrowalk Complex, Pasig City <br />
                    1604 Metro Manila
                  </address>
                </div>
              </div>
            </div>
          </div>      
        </div>
      </div>
      </div>
      <div className="container-fluid">
        <h4 className="mb-3 text-center">RAX Suite Office Address</h4>
        <div className="row">
            <iframe title="Monstarlab Main Office Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.087540057265!2d139.7105318651998!3d35.65021478927985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ca41218b77f%3A0xbc497396c952e711!2sMonstar%20Lab%2C%20Inc.!5e0!3m2!1sen!2sph!4v1576652380205!5m2!1sen!2sph" width="100%" height="600" frameBorder={ 0 } style={{ border: 0 }} loading="lazy" allowFullScreen></iframe>
        </div>
      </div>
    </>
  );
};

export default Contact;