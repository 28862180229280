import React from 'react';
import { Link } from '@reach/router';

const ConsultingServices = () => {
  return (
    <>
      <header>
        <div className="jumbotron jumbotron-fluid consulting-solutions-hero-image my-auto-substitute">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                  <h4 className="font-weight-normal h4-hero h4-style">Let's take it further</h4>
                  <h1 className="text-white font-weight-bold h1-hero">Consulting Services</h1>
              </div>``
            </div>
          </div>
        </div>
      </header>

      <div>
        <div className="container mt-5 mb-5 pt-5 pb-5">
          <div className="row p-15">
            <p>You can leave your automation goals with <strong>RAX</strong>, the <strong>Robotic Automation Expert</strong>, with which we support your passage from the time-consuming, recurring tasks of your human workforce to the rapid and foolproof work of RAX’s digital robots.</p>
            <p>Our solution is the <strong>RAX Methodology</strong>—seamless from start to finish, involving a unified ensemble of your people and ours and an integrated set of processes that guarantee successful outcomes. </p>
          </div>
          <div className="row mt-5 mb-5 p-15">
            <div className="mx-auto">
              <div className="align-items-center">
                <img className="img-fluid rounded" src="imgs/rax-methodology.png" alt="RAX Methodology" />
              </div>
            </div>
          </div>
          <div className="row mt-5 mb-5 p-15">
            <p>We work with your process team leads and members in identifying repetitive and rules-based tasks. Our automation analysts sift through these tasks to arrive at a scored list of those to prioritize, with quick wins and high returns. Our team of automation experts initializes a <strong>Design-Develop-Test-Release</strong> cycle that results in an automation library. Your Process Team Leader then monitors and analyzes the gains of the automation.</p>
          </div>
        </div>
      </div>

      <div className="container-fluid consulting-solutions-cta-bg">
        <div className="row block">
              <div className="text-center">
                <h2 className="text-white">Schedule for free assessment</h2>
                <p><Link className="btn btn-primary cta-consulting-btn text-dark" to="/contact" role="button">Contact Us Now</Link></p>
              </div>
          </div>
      </div>
    </>
  );
};

export default ConsultingServices;