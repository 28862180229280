import React from 'react';
import { Link } from '@reach/router';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

const NavigationPane = () => {
    return (
      <Navbar variant="dark" bg="dark" expand="lg" fixed="top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src="/imgs/RAX-logo-white.png" className="img-fluid logo-white" alt="RAX Logo" />
          </Link>
          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="ml-auto">
              <Nav.Link as={ Link } to="/">Home</Nav.Link>
              <Nav.Link as={ Link } to="/rax-suite">RAX Suite</Nav.Link>
              <NavDropdown title="Solutions">
                  <NavDropdown.Item as={ Link } to="/consulting-services">Consulting Services</NavDropdown.Item>
                  <NavDropdown.Item as={ Link } to="/case-studies">Case Studies</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="https://blog.raxsuite.com/">Blog</Nav.Link>
              <Nav.Link as={ Link } to="/team">Team</Nav.Link>
              <Nav.Link as={ Link } to="/contact">Contact</Nav.Link>
              <Nav.Link as={ Link } to="/freedownload">Download</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
};

export default NavigationPane;