import axios from 'axios';

const config = {
    timeout: 15000,
};

const apiUrl = process.env.REACT_APP_API_URL;
const services = {
    contactUs: async (data) => {
        const url = `${apiUrl}/contact-us`;
        return await axios.post(url, data, config);
    },

    downloadTrial: async (data) => {
        const url = `${apiUrl}/download-trial`;
        return await axios.post(url, data, config);
    },
}

export default services;