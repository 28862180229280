import React from 'react';

const ContactFormSuccess = () => {
  return (
    <>
      <h4 className="mb-3">Oops! Something went wrong in sending your inquiry.</h4>
      <p>Please send us a message in any of our social media accounts and we'll get back to you right away.</p>
      <p>Have a great day!</p>
    </>
  );
};

export default ContactFormSuccess;