import { useState, useEffect } from 'react';

const isFunction = (v) => {
    return typeof v === 'function';
};

const setErrorMessage = (valueValidator, initialValue, setError) => {
    const errorMessage = isFunction(valueValidator) ? valueValidator(initialValue) : null;
    
    Promise.resolve(errorMessage).then((actualErrorMessage) => {
        setError(actualErrorMessage);
    });
}

const useValidation = (valueSelector, valueValidator, initialValue) => {
    const [ dirty, setDirty ] = useState(false);
    const [ value, setValueWithoutValidation ] = useState(initialValue);

    const [ error, setError ] = useState();

    useEffect(() => {
        setErrorMessage(valueValidator, value, setError);
    }, [ value, valueValidator ]);

   
    const setValue = (value) => {
        setValueWithoutValidation(value);
        setDirty(true);
        setErrorMessage(valueValidator, value, setError);
    };

    const wrappedCallback = (...args) => {
        const currentValue = isFunction(valueSelector) ? valueSelector(...args) : null;
        setValue(currentValue);
    };

    const reset = (resetValue) => {
        setValue(resetValue);
        setDirty(false);
    };

    return [ wrappedCallback, {
        value, error, dirty, reset, setValue, setError, setDirty
    }];
};

export default useValidation;