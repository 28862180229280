import React from 'react';

const CopyrightPane = () => {
    return (
      <div className="container-fluid footer-copyright-container">
        <div className="container">
          <div className="row mt-auto mb-auto">
            <div className="col-md-6 col-sm-12">
              <p className="text-white ml-auto">Copyright &copy; All Rights Reserved. RAX Automation Suite by Monstar Lab Philippines.</p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default CopyrightPane;