import React, { useState, useRef } from 'react';
import FreeDownloadForm from 'components/FreeDownloadForm/FreeDownloadForm';
import FreeDownloadFormSuccess from 'components/FreeDownloadFormSuccess/FreeDownloadFormSuccess';
import FreeDownloadFormError from 'components/FreeDownloadFormError/FreeDownloadFormError';

const FreeDownload = () => {
  const formElt = useRef(null);

  const [ formStatus, setFormStatus ] = useState('');
  const onFormSuccess = () => {
    setFormStatus('success');
    scrollToForm();
  };

  const onFormError = (error) => {
    console.error(error);
    setFormStatus('error');
    scrollToForm();
  };

  const scrollToForm = () => {
    const y = formElt.current.getBoundingClientRect().top + window.pageYOffset - 250;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <>
      <header>
        <div className="jumbotron jumbotron-fluid free-version-hero-img my-auto-substitute">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h4 className="font-weight-normal h4-hero h4-style">RAX EDITOR Free Version</h4>
                <h1 className="text-white font-weight-bold h1-hero">Get ready to automate</h1>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="container mt-5 mb-5 pt-5 pb-5">
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <h4>What is RPA?</h4>
            <div className="vid mt-3">
              <iframe title="What is Robotic Process Automation?" width="480" height="270" src="https://www.youtube.com/embed/tjrkyJYChKY" loading="lazy" frameBorder="0" allowFullScreen ></iframe>
            </div>
            <h5 className="mt-5 font-weight-normal rax-head"><strong style={{ color: '#252525!important' }}>RAX Suite</strong> is your all-in-one tool for automating repetitive and manual tasks.</h5>
              <p>Our automation solution also covers productivity monitoring that allows you to keep track of work productivity improvements as you automate manual tasks.</p>
          </div>

          <div className="col-md-6 offset-md-1 col-sm-12" ref={ formElt }>
            {
              formStatus !== 'success' ?
                formStatus !== 'error' ?
                  <FreeDownloadForm 
                    onSuccess={ onFormSuccess }
                    onError={ onFormError }>
                  </FreeDownloadForm> :
                <FreeDownloadFormError /> :
                <FreeDownloadFormSuccess />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeDownload;