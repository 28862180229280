import React, { useEffect } from 'react';
import { Router, useLocation } from '@reach/router';

import NavigationPane from 'components/NavigationPane/NavigationPane';
import FooterPane from 'components/FooterPane/FooterPane';
import CookiesPane from 'components/CookiesPane/CookiesPane';
import CopyrightPane from 'components/CopyrightPane/CopyrightPane';
import Home from 'pages/Home/Home';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import Team from 'pages/Team/Team';
import RaxSuite from 'pages/RaxSuite/RaxSuite';
import ConsultingServices from 'pages/ConsultingServices/ConsultingServices';
import CaseStudies from 'pages/CaseStudies/CaseStudies';
import Contact from 'pages/Contact/Contact';
import FreeDownload from 'pages/FreeDownload/FreeDownload';
import NotFound from 'pages/NotFound/NotFound';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ location.pathname ]);

  return (
    <>
      <NavigationPane />
      <Router>
        <Home path="/" />
        <RaxSuite path="/rax-suite" />
        <ConsultingServices path="/consulting-services" />
        <CaseStudies path="/case-studies" />
        <PrivacyPolicy path="/privacy-policy" />
        <Team path="/team" />
        <Contact path="/contact" />
        <FreeDownload path="/freedownload" />
        <NotFound default />
      </Router>
      <CookiesPane />
      <FooterPane />
      <CopyrightPane />
    </>
  );
}

export default App;
