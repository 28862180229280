import React, { useState } from 'react';
import useValidation from 'hooks/useValidation';
import services from 'services/services';

const SURVEY_OTHERS_VALUE = 'others';
const selectorText = (evt) => {
  if (evt) {
    return evt.target.value.trim();
  }

  return null;
}; 

const selectorSelect = (evt) => {
  if (evt) {
    return evt.target.options[evt.target.selectedIndex].text.trim();
  }

  return null;
};

const validatorRequired = (label) => {
  return (value) => {
    if (!value) {
      return `${label} is required`;
    }

    return null;
  };
};

const validatorMaxChars = (label, maxChars) => {
  return (value) => {
    if (value.length > maxChars) {
        return `${label} must not exceed ${maxChars} characters`;
    }

    return null;
  };
}

const validatorEmail = () => {
  return (value) => {
    if ((value.match(/@/g) || []).length !== 1) {
        return 'Email format is invalid';
      }
    
      return null;
  };
};

const validatorNumber = (label) => {
  return (value) => {
    const matches = value.match(/\d+/g);
    if (!((matches || []).length === 1 && matches[0] === value)) {
      return `${label} only accepts numbers`;
    }

    return null;
  };
};

const validators = (args) => {
  return (value) => {
      return args.reduce((currError, currArg) => {
        if (!currError) {
            return currArg(value);
        }

        return currError;
      }, null);
  };
};

const ContactForm = ({ onSuccess, onError }) => {
  const [ onChangeFirstName, firstName ] = 
        useValidation(selectorText, 
            validators([ 
                validatorRequired('First name'),
                validatorMaxChars('First name', 40),
            ]));

  const [ onChangeLastName, lastName ] = 
        useValidation(selectorText, 
            validators([ 
                validatorRequired('Last name'),
                validatorMaxChars('Last name', 25),
            ]));

  const [ onChangeEmail, email ] = 
        useValidation(selectorText, 
            validators([
                validatorRequired('Email'),
                validatorEmail(),
                validatorMaxChars('Email', 64),
            ]));

  const [ onChangeContactNum, contactNum ] =
        useValidation(selectorText, 
            validators([
                validatorRequired('Contact number'),
                validatorNumber('Contact number'),
                validatorMaxChars('Contact number', 15),
            ]));

  const [ onChangeCompany, company ] = 
        useValidation(selectorText, 
            validators([ 
                validatorRequired('Company'),
                validatorMaxChars('Company', 80),
            ]));

  const [ onChangeIndustry, industry ] = 
        useValidation(selectorSelect, 
            validators([
                validatorRequired('Industry'),
                validatorMaxChars('Industry', 80),
            ]));

  const [ onChangeSurvey, survey ] =
        useValidation(selectorText, 
            validators([ 
                validatorRequired('Field'),
                validatorMaxChars('Field', 255),
            ]));

  const [ onChangeSurveyOthers, surveyOthers ] =
        useValidation(selectorText, 
            validators([
                validatorRequired('Others'),
                validatorMaxChars('Others', 255),
            ]));

  const [ onChangeMessage, message ] = 
        useValidation(selectorText, 
            validators([
                validatorRequired('Message'),
                validatorMaxChars('Message', 255),
            ]));
    
  const [ isSubmitted, setSubmitted ] = useState(false);
  const [ isLoading, setLoading ] = useState(false);

  const hasValidationError = (prop) => {
    return (prop.dirty || isSubmitted) && prop.error;
  };

  const getCompleteClassName = (prop, errorClass, defaultClass) => {
    if (hasValidationError(prop)) {
      return `${defaultClass} ${errorClass}`;
    }

    return defaultClass;
  };

  const getErrorMessage = (prop) => {
    if (hasValidationError(prop)) {
      return <div className="invalid-feedback">{ prop.error }</div>
    }

    return <></>;
  };

  const onFormSubmit = (evt) => {
    evt.preventDefault();

    if (firstName.error
          || lastName.error
          || email.error 
          || contactNum.error
          || company.error 
          || industry.error 
          || survey.error 
          || (survey.value === SURVEY_OTHERS_VALUE && surveyOthers.error) 
          || message.error) {
      setSubmitted(true);
      return;
    }

    setLoading(true);

    try {
      let payload = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        contactNumber: contactNum.value,
        company: company.value,
        industry: industry.value,
        survey: survey.value === SURVEY_OTHERS_VALUE ? surveyOthers.value : survey.value,
        message: message.value,
      }

      services.contactUs(payload).then(({ data }) => {
        if (data.status === 'success') {
          onSuccess(data);
        } else {
          onError(data);
        }
      }).catch((error) => {
        onError(error);
      });
    } catch (error) {
      onError(error);
    }
  };

  return (
    <>
      <h4 className="mb-3">Get In Touch</h4>
      <form noValidate onSubmit={ onFormSubmit }>
        <div className="form-row form-group">
          <div className="col">
            <input 
                type="text" 
                placeholder="First name"
                disabled={ isLoading }
                className={ getCompleteClassName(firstName, 'is-invalid', 'form-control')}
                onChange={ onChangeFirstName } />
            { getErrorMessage(firstName) }
          </div>
          <div className="col">
            <input 
                type="text" 
                placeholder="Last name"
                disabled={ isLoading }
                className={ getCompleteClassName(lastName, 'is-invalid', 'form-control' )} 
                onChange={ onChangeLastName } />
            { getErrorMessage(lastName) }
          </div>
        </div>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-12 col-sm-12">
                <input 
                    type="email" 
                    placeholder="Email"
                    disabled={ isLoading }
                    className={ getCompleteClassName(email, 'is-invalid', 'form-control') } 
                    onChange={ onChangeEmail } />
                { getErrorMessage(email) }
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-12 col-sm-12">
                <input 
                    type="text" 
                    placeholder="Contact Number"
                    disabled={ isLoading }
                    className={ getCompleteClassName(contactNum, 'is-invalid', 'form-control') }
                    onChange={ onChangeContactNum } />
                { getErrorMessage(contactNum) }
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-12 col-sm-12">
                <input 
                    type="text" 
                    placeholder="Company"
                    disabled={ isLoading }
                    className={ getCompleteClassName(company, 'is-invalid', 'form-control') } 
                    onChange={ onChangeCompany } />
                  { getErrorMessage(company) }
              </div> 
            </div>
          </div>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-12 col-sm-12">
                <select
                    defaultValue="" 
                    disabled={ isLoading }
                    className={ getCompleteClassName(industry, 'is-invalid', 'custom-select') }
                    onChange={ onChangeIndustry }>
                  <option disabled value="">
                    Industry
                  </option>
                  <option value=''>All Specializations</option>
                    <optgroup className="optgroup" label="Accounting/Finance">
                      <option value="130,131,132,135" className="opt-indent">All Accounting/Finance</option>
                      <option value="130" className="opt-indent">Audit &amp; Taxation</option>
                      <option value="135" className="opt-indent">Banking/Financial</option>
                      <option value="132" className="opt-indent">Corporate Finance/Investment</option>
                      <option value="131" className="opt-indent">General/Cost Accounting</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Admin/Human Resources">
                      <option value="133,137,146,148" className="opt-indent">All Admin/Human Resources</option>
                      <option value="133" className="opt-indent">Clerical/Administrative</option>
                      <option value="137" className="opt-indent">Human Resources</option>
                      <option value="146" className="opt-indent">Secretarial</option>
                      <option value="148" className="opt-indent">Top Management</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Arts/Media/Communications">
                      <option value="100,101,106,141" className="opt-indent">All Arts/Media/Communications</option>
                      <option value="100" className="opt-indent">Advertising</option>
                      <option value="101" className="opt-indent">Arts/Creative Design</option>
                      <option value="106" className="opt-indent">Entertainment</option>
                      <option value="141" className="opt-indent">Public Relations</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Building/Construction">
                      <option value="150,180,184,198" className="opt-indent">All Building/Construction</option>
                      <option value="180" className="opt-indent">Architect/Interior Design</option>
                      <option value="184" className="opt-indent">Civil Engineering/Construction</option>
                      <option value="150" className="opt-indent">Property/Real Estate</option>
                      <option value="198" className="opt-indent">Quantity Surveying</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Computer/Information Technology">
                      <option value="191,192,193" className="opt-indent">All Computer/Information Technology</option>
                      <option value="192" className="opt-indent">IT - Hardware</option>
                      <option value="193" className="opt-indent">IT - Network/Sys/DB Admin</option>
                      <option value="191" className="opt-indent">IT - Software</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Education/Training">
                      <option value="105,121" className="opt-indent">All Education/Training</option>
                      <option value="105" className="opt-indent">Education</option>
                      <option value="121" className="opt-indent">Training &amp; Dev.</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Engineering">
                      <option value="185,186,187,188,189,190,195,200" className="opt-indent">All Engineering</option>
                      <option value="185" className="opt-indent">Chemical Engineering</option>
                      <option value="187" className="opt-indent">Electrical Engineering</option>
                      <option value="186" className="opt-indent">Electronics Engineering</option>
                      <option value="189" className="opt-indent">Environmental Engineering</option>
                      <option value="200" className="opt-indent">Industrial Engineering</option>
                      <option value="195" className="opt-indent">Mechanical/Automotive Engineering</option>
                      <option value="190" className="opt-indent">Oil/Gas Engineering</option>
                      <option value="188" className="opt-indent">Other Engineering</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Healthcare">
                      <option value="111,112,113" className="opt-indent">All Healthcare</option>
                      <option value="113" className="opt-indent">Doctor/Diagnosis</option>
                      <option value="112" className="opt-indent">Pharmacy</option>
                      <option value="111" className="opt-indent">Nurse/Medical Support</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Hotel/Restaurant">
                      <option value="107,114" className="opt-indent">All Hotel/Restaurant</option>
                      <option value="107" className="opt-indent">Food/Beverage/Restaurant</option>
                      <option value="114" className="opt-indent">Hotel/Tourism</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Manufacturing">
                      <option value="115,140,194,196,197" className="opt-indent">All Manufacturing</option>
                      <option value="115" className="opt-indent">Maintenance</option>
                      <option value="194" className="opt-indent">Manufacturing</option>
                      <option value="196" className="opt-indent">Process Design &amp; Control</option>
                      <option value="140" className="opt-indent">Purchasing/Material Mgmt</option>
                      <option value="197" className="opt-indent">Quality Assurance</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Sales/Marketing">
                      <option value="139,142,143,144,145,149,151,201,202,203" className="opt-indent">All Sales/Marketing</option>
                      <option value="203" className="opt-indent">Digital Marketing</option>
                      <option value="142" className="opt-indent">Sales - Corporate</option>
                      <option value="202" className="opt-indent">E-commerce</option>
                      <option value="139" className="opt-indent">Marketing/Business Dev</option>
                      <option value="149" className="opt-indent">Merchandising</option>
                      <option value="145" className="opt-indent">Retail Sales</option>
                      <option value="143" className="opt-indent">Sales - Eng/Tech/IT</option>
                      <option value="144" className="opt-indent">Sales - Financial Services</option>
                      <option value="151" className="opt-indent">Telesales/Telemarketing</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Sciences">
                      <option value="102,103,108,109,181,182,183,199,204" className="opt-indent">All Sciences</option>
                      <option value="103" className="opt-indent">Actuarial/Statistics</option>
                      <option value="102" className="opt-indent">Agriculture</option>
                      <option value="181" className="opt-indent">Aviation</option>
                      <option value="204" className="opt-indent">Biomedical</option>
                      <option value="182" className="opt-indent">Biotechnology</option>
                      <option value="183" className="opt-indent">Chemistry</option>
                      <option value="108" className="opt-indent">Food Tech/Nutritionist</option>
                      <option value="109" className="opt-indent">Geology/Geophysics</option>
                      <option value="199" className="opt-indent">Science &amp; Technology</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Services">
                      <option value="118,119,120,134,138,147,152" className="opt-indent">All Services</option>
                      <option value="119" className="opt-indent">Security/Armed Forces</option>
                      <option value="134" className="opt-indent">Customer Service</option>
                      <option value="147" className="opt-indent">Logistics/Supply Chain</option>
                      <option value="138" className="opt-indent">Law/Legal Services</option>
                      <option value="118" className="opt-indent">Personal Care</option>
                      <option value="120" className="opt-indent">Social Services</option>
                      <option value="152" className="opt-indent">Tech &amp; Helpdesk Support</option>
                    </optgroup>
                    <optgroup className="optgroup" label="Others">
                      <option value="90,104,110,116,117" className="opt-indent">All Others</option>
                      <option value="110" className="opt-indent">General Work</option>
                      <option value="104" className="opt-indent">Journalist/Editors</option>
                      <option value="117" className="opt-indent">Publishing</option>
                      <option value="116" className="opt-indent">Others</option>
                    </optgroup>
                </select>
                { getErrorMessage(industry) }
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row form-group">
              <div className="col-md-12 col-sm-12">
                <select 
                    defaultValue=""
                    disabled={ isLoading }
                    className={ getCompleteClassName(survey, 'is-invalid', 'custom-select') }
                    onChange={ onChangeSurvey }>
                  <option disabled value="">
                    Where Did You Hear About Us?
                  </option>
                    <option value="Email / Newsletter">Email / Newsletter</option>
                    <option value="Facebook">Facebook</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="Twitter">Twitter</option>
                    <option value="Referred by Colleague">Referred by Colleague</option>
                    <option value="Google Search">Google Search</option>
                    <option value="Seminar / Conference">Seminar / Conference</option>
                    <option value="TV / Radio / Newspaper">TV / Radio / Newspaper</option>
                    <option value="others">Others (please specify)</option>
                </select>
                { getErrorMessage(survey) }
              </div>
            </div>
            <div className="form-row form-group">
              <div className="col-md-12 col-sm-12">
                {
                  survey.value === SURVEY_OTHERS_VALUE ?
                    <>
                      <input 
                          type="text" 
                          placeholder="Others"
                          disabled={ isLoading }
                          className={ getCompleteClassName(surveyOthers, 'is-invalid', 'form-control') }
                          onChange={ onChangeSurveyOthers } />
                      { getErrorMessage(surveyOthers) }
                    </> :
                    <></>
                }
              </div>
            </div>
          </div>

          <div className="form-group">
            <textarea
                placeholder="Type your message here..." 
                disabled={ isLoading }
                rows="10"
                className={ getCompleteClassName(message, 'is-invalid', 'form-control') }
                onChange={ onChangeMessage }>
            </textarea>
            { getErrorMessage(message) }
          </div>
          {
              !isLoading ?
                <input type="submit" className="btn btn-primary btn-lg btn-block contact-submit-btn" value="Submit form" /> :
                <input type="button" className="btn btn-primary btn-lg btn-block contact-submit-btn" value="Submitting..." disabled />
          }
          
        </form>
    </>
  );
};

export default ContactForm;