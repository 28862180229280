import React from 'react';

const ContactFormSuccess = () => {
  return (
    <>
      <h4 className="mb-3">Thank you for getting in touch!</h4>
      <p>We appreciate you contacting us. One of our colleagues will get in touch with you soon!</p>
      <p>Have a great day!</p>
    </>
  );
};

export default ContactFormSuccess;