import React from 'react';
import { Link } from '@reach/router';

const FooterPane = () => {
  return (
      <footer className="py-5 bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <img src="/imgs/RAX-logo-white.png" className="img-fluid logo-white" alt="RAX Logo" />
              <p>RAX Automation Suite frees your personnel from tedious monotonous tasks and enables them to concentrate on the important tasks. This is a powerful tool, compatible with Windows operating laptop and desktop. </p>
              <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                <h5 className="h5">Connect with Us</h5>
                <ul className="list-unstyled list-inline socials text-left">
                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/raxautomationsuite" target="_blank" rel="noreferrer"> <i className="fab fa-facebook-f"></i> </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://twitter.com/raxsuite" target="_blank" rel="noreferrer"> <i className="fab fa-twitter"></i> </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/company/raxsuite/" target="_blank" rel="noreferrer"> <i className="fab fa-linkedin-in"></i> </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://medium.com/@raxsuite" target="_blank" rel="noreferrer"> <i className="fab fa-medium"></i> </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.youtube.com/channel/UCL-W5wNyTY9bSa2WUpOAx0w" target="_blank" rel="noreferrer"> <i className="fab fa-youtube"></i> </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <h5 className="h5">RAX (Robotic Automation Expert)</h5>
              <ul className="list-unstyled">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/rax-suite">RAX Suite</Link></li>
                <li><Link to="/consulting-services">Consulting Services</Link></li>
                <li><Link to="/case-studies">Case Studies</Link></li>
                <li><a href="https://blog.raxsuite.com/" target="_blank" rel="noreferrer">Blog</a></li>
                <li><Link to="/contact">Contact</Link></li>
                <li><Link to="/freedownload" target="_blank">Download</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              </ul>
              <address className="mt-2">
                <strong style={{ color: '#ffffff' }}>Powered by</strong><br />
                <a href="https://monstar-lab.com/global/" target="_blank" rel="noreferrer">www.monstar-lab.com</a>  
              </address>
            </div>
            <div className="col-sm-12 col-md-4">
              <h5 className="h5">Contact Us </h5>
              <div className="bd-example">
    
                <address>
                  <strong style={{ color: '#ffffff' }}>Philippines</strong><br />
                  Unit 200A 2/F Weremote, Building C<br />
                  Metrowalk Complex, Pasig City <br />
                  1604 Metro Manila
                </address>
    
                <address>
                  <strong style={{ color: '#ffffff' }}>Japan</strong><br />
                  1-1-39, Hiroo<br />
                  Ebisu Prime Square Tower, 4F<br />
                  Shibuya-ku, Tokyo, 150-0012<br />
                  <abbr title="Phone">Phone:</abbr> +81 344 557 243
                </address>
            </div>
          </div>
        </div>
        </div>
      </footer>
    );
};

export default FooterPane;