import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import Analytics from 'react-ga';
import { Link } from '@reach/router';

import useStorage from 'hooks/useStorage';

const CookiesPane = () => {
    const [ hasConsent, setConsent ] = useStorage('rax-cookie-consent');
    const onAcceptCookies = () => {
        setConsent(true);
    };
    
    useEffect(() => {
        if (hasConsent) {    
            const pixelId = process.env.REACT_APP_PIXEL_ID;
            ReactPixel.init(pixelId);

            const gtmId = process.env.REACT_APP_GTM_ID;
            TagManager.initialize({ gtmId: gtmId });
    
            const gaId = process.env.REACT_APP_GA_ID;
            Analytics.initialize(gaId);
        }
    }, [ hasConsent ]);

    return (
        <>
        {
            !hasConsent ?
              <div className="container-fluid use-cookies-container">
                <div className="row">
                <div className="col-sm-12 col-md-10">
                    <p>Our website uses cookies to help enhance your browsing experience. Continue to browse our site if you agree to our use of cookies as described in our Privacy Policy. Otherwise, you may opt to change your cookie settings. Read our <Link className="text-white" to="/privacy-policy">Privacy Policy</Link></p>
                </div>
                <div className="col-sm-12 col-md-2 my-auto">
                    <div className="text-center">
                    <button className="btn btn-primary cookies-btn cookies-consent-btn" onClick={ onAcceptCookies }>Got It!!</button>
                    </div>
                </div>
                </div>
              </div> : <></>
        }
        </>
    )
};

export default CookiesPane;
