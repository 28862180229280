import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import { ProgressBar } from 'react-bootstrap';

const CaseStudies = () => {
  const [ progress, setProgress ] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      let scrolled = (winScroll / height) * 100;
      setProgress(scrolled);
    };

    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);
  
  return (
    <>
      <div className="header">
        <h2>Scroll Indicator</h2>
        <div className="progress-container">
          <ProgressBar now={ progress } />
        </div>
      </div>

      <div className="container text-center mt-5 mb-5 pt-5 pb-5">
        <h1>Case Studies</h1>
        <h4>Real workplace situations</h4>
        <hr className="my-4" />
      </div>

      <div className="container mt-5 mb-5">
        <p>Discover the power of RPA through <strong>RAX Suite</strong> in addressing real commonplace issues involving data retrieval, data extraction, transformation, and loading, data analysis, product and client bridging, reports creation and dispatch, and workload assignment tool. </p>
      </div>

      <div className="container">
        <div className="row setup-content mt-5 mb-5 case-study-container-height">
          <div className="col-sm-12 col-md-12 case-study-page">
            <div className="row equal-height-column">
              <div className="col-md-6 col-sm-12 bg-gray py-auto">
                <div className="mt-5 mb-5 text-white p-50">
                  <h5 className="mt-0 mb-1 font-weight-normal">CASE</h5>
                  <h2 className="text-white">Data Retrieval</h2>
                  <div className="media-body case-studies-issue-container">
                    <h5 className="text-warning text-uppercase font-weight-normal mt-5">Issue</h5>
                    <p className="case-studies-par">Wasted time watching folders, systems, and sites for new files; more wasted time downloading, extracting, renaming, and organizing electronic files.</p>
                    <h1 className="display-1 case-study-page-number">01</h1>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="media mt-5 mb-5 media-cross-platform-integration">
                  <img src="imgs/case-solution.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                  <div className="media-body">
                    <h5 className="mt-0 subhead">Solutions</h5>
                    <p className="case-studies-solutions-par">Template and rule-driven report generation, output conversion tools, and  scheduled report delivery tools employed.</p>
                  </div>
                </div>

                <div className="media mb-5 media-cross-platform-integration">
                  <img src="imgs/performance-benefits.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                  <div className="media-body">
                    <h5 className="mt-0 subhead">Benefits</h5>
                    <p className="case-studies-solutions-par">Eliminated manual intervention.</p>
                    <img src="imgs/case-1-benefits.svg" className="img-fluid" alt="Case 1 Benefits" />
                  </div>
                </div>

                <div className="media mb-5 media-cross-platform-integration">
                  <img src="imgs/applicable-industries.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                  <div className="media-body">
                    <h5 className="mt-0 subhead">Applicable in</h5>
                    <p className="case-studies-solutions-par">Banking, Hospital, Healthcare, Insurance, Law Firms</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div className="row setup-content mt-5 mb-5 case-study-container-height">
        <div className="col-sm-12 col-md-12">
          <div className="row equal-height-column">
            <div className="col-md-6 col-sm-12 bg-gray py-auto">
              <div className="mt-5 mb-5 text-white p-50">
                <h5 className="mt-0 mb-1 font-weight-normal">CASE</h5>
                <h2 className="text-white">Data Extraction, Transformation, and Loading (ETL)</h2>
                <div className="media-body case-studies-issue-container">
                  <h5 className="text-warning text-uppercase font-weight-normal mt-5">Issue</h5>
                  <p className="case-studies-par">Data manually gathered from various sources such as Excel, CSV, emails, and other files, then normalized, corrected and loaded into another system for further processing.</p>
                  <h1 className="display-1 case-study-page-number">02</h1>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="media mt-5 mb-5 media-cross-platform-integration">
                <img src="imgs/case-solution.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                <div className="media-body">
                  <h5 className="mt-0 subhead">Solutions</h5>
                  <ul className="case-studies-solutions-par">
                    <li className="font-weight-normal font-gray">Data normalization and correction scripts running on Excel created</li>
                    <li className="font-weight-normal font-gray">Internet research tools crawl website and scrape data for validation</li>
                    <li className="font-weight-normal font-gray">Automated response tools send responses to queries received</li>
                    <li className="font-weight-normal font-gray">File conversion tools employed</li>
                  </ul>
                </div>
              </div>
              <div className="media mb-5 media-cross-platform-integration">
                <img src="imgs/performance-benefits.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                <div className="media-body">
                  <h5 className="mt-0 subhead">Benefits</h5>
                  <p className="case-studies-solutions-par">Reduced human errors and turnaround time 12 times faster.</p>
                  <img src="imgs/case-2-benefits.svg" className="img-fluid" alt="Case 2 Benefits" />
                </div>
              </div>
              <div className="media mb-5 media-cross-platform-integration">
                <img src="imgs/applicable-industries.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                <div className="media-body">
                  <h5 className="mt-0 subhead">Applicable in</h5>
                  <p className="case-studies-solutions-par">Accounting, BPO, Account Management, Client Management</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row setup-content mt-5 mb-5 case-study-container-height">
        <div className="col-sm-12 col-md-12">
          <div className="row equal-height-column">
            <div className="col-md-6 col-sm-12 bg-gray py-auto">
              <div className="mt-5 mb-5 text-white p-50">
                <h5 className="mt-0 mb-1 font-weight-normal">CASE</h5>
                <h2 className="text-white">Data Analysis</h2>
                <div className="media-body case-studies-issue-container">
                  <h5 className="text-warning text-uppercase font-weight-normal mt-5">Issue</h5>
                  <p className="case-studies-par">Data manually inspected for anomalies (e.g., prices outside normal range) and trends when clear rules and patterns can be observed and applied.</p>
                  <h1 className="display-1 case-study-page-number">03</h1>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="media mt-5 mb-5 media-cross-platform-integration">
                <img src="imgs/case-solution.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                <div className="media-body">
                  <h5 className="mt-0 subhead">Solutions</h5>
                  <ul className="case-studies-solutions-par">
                    <li className="font-weight-normal font-gray">Rules-based checking tools employed to automatically detect anomaly in the data being processed</li>
                    <li className="font-weight-normal font-gray">Data validation tools used to ensure data are within expected range of values</li>
                    <li className="font-weight-normal font-gray">Data consolidation applied to unify multiple sources into single report file</li>
                  </ul>
                </div>
              </div>
              <div className="media mb-5 media-cross-platform-integration">
                <img src="imgs/performance-benefits.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                <div className="media-body">
                  <h5 className="mt-0 subhead">Benefits</h5>
                  <p className="case-studies-solutions-par">Better quality and consistent data.</p>
                  <img src="imgs/case-3-benefits.svg" className="img-fluid" alt="Case 3 Benefits" />
                </div>
              </div>
              <div className="media mb-5 media-cross-platform-integration">
                <img src="imgs/applicable-industries.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                <div className="media-body">
                  <h5 className="mt-0 subhead">Applicable in</h5>
                  <p className="case-studies-solutions-par">Banking, Hospital, Healthcare, Insurance, Law Firms</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row setup-content mt-5 mb-5 case-study-container-height">
        <div className="col-sm-12 col-md-12">
          <div className="row equal-height-column">
            <div className="col-md-6 col-sm-12 bg-gray py-auto container-height-580">
              <div className="mt-5 mb-5 text-white p-50">
                <h5 className="mt-0 mb-1 font-weight-normal">CASE</h5>
                <h2 className="text-white">Reports Creation and Dispatch</h2>
                <div className="media-body case-studies-issue-container">
                  <h5 className="text-warning text-uppercase font-weight-normal mt-4">Issue</h5>
                  <p className="case-studies-par">Data manually compiled, formatted, and dispatched to local offices.</p>
                  <h1 className="display-1 case-study-page-number">04</h1>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="media mt-5 mb-5 media-cross-platform-integration">
                <img src="imgs/case-solution.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                <div className="media-body">
                  <h5 className="mt-0 subhead">Solutions</h5>
                  <p className="case-studies-solutions-par">
                    Template and rule-driven report generation, output conversion tools, and  scheduled report delivery tools employed
                  </p>
                </div>
              </div>
              <div className="media mb-5 media-cross-platform-integration">
                <img src="imgs/performance-benefits.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                <div className="media-body">
                  <h5 className="mt-0 subhead">Benefits</h5>
                  <p className="case-studies-solutions-par">Better quality and consistent data.</p>
                  <img src="imgs/case-4-benefits.svg" className="img-fluid" alt="Case 4 Benefits" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row setup-content mt-5 mb-5 case-study-container-height">
        <div className="col-sm-12 col-md-12">
          <div className="row equal-height-column">
            <div className="col-md-6 col-sm-12 bg-gray py-auto container-height-580">
              <div className="mt-5 mb-5 text-white p-50">
                <h5 className="mt-0 mb-1 font-weight-normal">CASE</h5>
                <h2 className="text-white">Workload Assignment Tool</h2>
                <div className="media-body case-studies-issue-container">
                  <h5 className="text-warning text-uppercase font-weight-normal mt-4">Issue</h5>
                  <p className="case-studies-par">Task prioritization, allocation, and distribution done manually, thus taking time away from managers to do more important functions.</p>
                  <h1 className="display-1 case-study-page-number">05</h1>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="media mt-5 mb-5 media-cross-platform-integration">
                <img src="imgs/case-solution.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                  <div className="media-body">
                    <h5 className="mt-0 subhead">Solutions</h5>
                    <ul className="case-studies-solutions-par">
                      <li className="font-weight-normal font-gray">Rules-based task prioritization and assignment tools that automatically assigns tasks to available staff employed.</li>
                      <li className="font-weight-normal font-gray">Monitoring and notification tools utilized to keep track of staff activities and output.</li>
                    </ul>
                  </div>
                </div>
                <div className="media mb-5 media-cross-platform-integration">
                  <img src="imgs/performance-benefits.svg" className="mr-3 img-fluid case-studies-solution-icon" alt="Case Studies - Data Retrieval Solutions" />
                  <div className="media-body">
                    <h5 className="mt-0 subhead">Benefits</h5>
                    <p className="case-studies-solutions-par">Better quality and consistent data.</p>
                    <img src="imgs/case-5-benefits.svg" className="img-fluid" alt="Case 5 Benefits" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid case-study-cta mt-5 pt-5 pb-5">
        <div className="text-center mt-5 mb-5 pt-5 pb-5 text-white">
          <h2 className="text-white">Get your free assessment with us</h2>
            <p><Link className="btn btn-primary" to="/contact" role="button">Learn More</Link></p>
        </div>
      </div>
    </>
  );
};

export default CaseStudies;