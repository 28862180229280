import React, { useState, useRef } from 'react';
import { Link } from '@reach/router';
import Slider from 'react-slick';
import { Carousel } from 'react-bootstrap';

const HomePage = () => {
    const sliderSettings = {
        speed: 500,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 1300,
        autoplay: true,
        nextArrow: <></>,
        prevArrow: <></>,
    };

    const [ index, setIndex ] = useState(0);
    const sectionElt = useRef(null);

    const scrollToSection = () => {
      const y = sectionElt.current.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({ top: y, behavior: 'smooth' });
    };    

    return (
        <>
          <header className="masthead">
            <div className="container h-100">
              <div className="row h-100 align-items-center justify-content-center text-center">
                <div className="col-md-10 align-self-end">
                  <h1 className="text-white font-weight-bold h1-hero typewriter rax-suite-container-typewriter">RAX Automation Suite</h1>
                  <h4 className="font-weight-normal h4-hero">Evolve your business. Empower your people.</h4>
                </div>
                <div className="col-md-8 align-self-baseline">
                  <p className="text-white-75 font-weight-normal mb-5 p-hero">RAX Suite is your next big, bold step to enterprise RPA success.</p>
                  <Link className="btn btn-primary btn-xl btn-learn-more mb-2 js-scroll-trigger" to="/rax-suite">Learn More</Link>
                  &nbsp;
                  <Link className="btn btn-primary btn-xl mb-2 js-scroll-trigger" to="/freedownload">Try for free</Link>
                  <div className="mx-auto mt-6">
                    <i className="fas fa-chevron-down floating" style={{ marginLeft: '0px', cursor: 'pointer' }} onClick={ scrollToSection }></i>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div ref={ sectionElt } className="container-fluid clients-container pt-3 pb-3">
            <div className="container mb-4 pt-4">
              <h5 className="sm-title pb-3">Be one of the organizations that enjoy RAX Suite to improve their work efficiency</h5>          
              <Slider { ...sliderSettings} className="customer-logos mt-3">
                <div className="slide my-auto"><img src="imgs/zenrin-logo.png" className="img-fluid logo-img-size" alt="Zenrin DataCom" /></div>
                <div className="slide my-auto"><img src="imgs/kobe-logo.png" className="img-fluid logo-img-size" alt="Kobe" /></div>
                <div className="slide my-auto"><img src="imgs/safetylife-logo.png" className="img-fluid logo-img-size" alt="Safety Life" /></div>
                <div className="slide my-auto"><img src="imgs/funai-logo.png" className="img-fluid logo-img-size" alt="Funai Soken" /></div>
                <div className="slide my-auto"><img src="imgs/rantei-logo.png" className="img-fluid logo-img-size" alt="Rantei" /></div>
                <div className="slide my-auto"><img src="imgs/ricoh-logo.jpg" className="img-fluid logo-img-size" alt="RICOH" /></div>
              </Slider>
            </div>
          </div>

          <div className="container mt-5 mb-5 pt-5 pb-5">
            <h5 className="sm-title">Products</h5>
            <hr className="divider my-4" />
            <h1 className="my-2 text-center text-dark">RAX automates via two applications:</h1>
            <div className="row row-equal-height ml-auto mr-auto">
              <div className="col-md-6 col-sm-12 p-5 text-center">
                <h2 className="h2">RAX MONITOR</h2>
                <p className="paragraph-intro">Tracks your people’s desktop procedures of which rules-based tasks are identified for automation.</p>
                <Link className="btn btn-primary-icon hvr-icon-forward" to="/rax-suite" role="button">Read More  <i className="fa fa-chevron-circle-right hvr-icon"></i></Link>
                <br />
                <img className="img-fluid rounded mt-5" src="imgs/rax-monitor-laptop.jpg" alt="Running RAX Monitor in a Laptop" />
              </div>
              <div className="col-md-6 col-sm-12 p-5 text-center">
                <h2 className="h2">RAX EDITOR</h2>
                <p className="paragraph-intro">Where virtual agents are made to perform the automation.</p>
                <Link className="btn btn-primary-icon hvr-icon-forward" to="/rax-suite" role="button">Read More  <i className="fa fa-chevron-circle-right hvr-icon"></i></Link>
                <br />
                <img className="img-fluid rounded mt-5" src="imgs/rax-editor-dekstop.png" alt="Running RAX EDITOR in a Desktop Computer Set-up" />
              </div>
            </div>
          </div>

          <div className="cta1-bg pt-5 pb-5">
            <div className="container text-center text-lg-left">
              <h3 className="h3-cta">Schedule for<br/><span className="text-uppercase">free assessment</span></h3>
              <p className="lead-cta">Our dedicated team will get in touch with you to schedule an assessment<br/> and set-up a meeting to evaluate the essential needs of your company.</p>
              <Link className="btn btn-primary" to="/contact" role="button">Request a Demo Now</Link>    
            </div>
          </div>

          <div className="container-fluid mt-5 mb-5 pt-5">
            <h5 className="sm-title">Solutions</h5>
            <hr className="divider my-4" />
            <div className="solutions-container mx-auto">
              <h5 className="my-4 text-center font-weight-normal mb-5 pb-5 rax-head">RAX Automation Suite will see you through your laborsaving process in whichever work operation or business areas you are engaged.</h5>
            </div>
          </div>

          <div className="row no-gutters mb-5 pb-5">
            <div className="d-flex parent flex-wrap">
              <div className="col-md-6 col-sm-12 my-auto text-center col-sm-push-12">
                <div className="container-80 mx-auto">
                  <h2 className="h2 mt-3">Let’s take it further</h2>
                  <p>Our solution is the RAX Methodology— seamless from start to finish, involving a unified ensemble of your people and ours with an integrated set of processes that guarantee successful outcomes.</p>
                  <Link className="btn btn-primary mb-5" to="/consulting-services" role="button">Find Out More</Link>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-sm-pull-12 text-center pb-3">
                <img src="imgs/cta-1-img.png" className="img-fluid img-70" alt="RAX Methology" />
              </div> 
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12 text-center pt-3">
              <img src="imgs/cta-2-img.png" className="img-fluid img-70" alt="Real workplace situations" />
            </div>

            <div className="col-md-6 col-sm-12 my-auto mx-auto text-center pb-3">
              <div className="container-80 mx-auto">
                <h2 className="h2 mt-3">Real workplace situations</h2>
                <p>Discover the power of RPA through RAX Suite in addressing real commonplace issues involving data retrieval, data extraction, data analysis, product and client bridging, reports creation and dispatch, and workload assignment tool.</p>
                <Link className="btn btn-primary" to="/case-studies" role="button">Learn More</Link>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-5 mb-5 pb-5 pt-5 gray-bg">
            <div className="container mt-5 mb-5 pt-5 pb-5">
              <h2 className="text-center">What is Robotic Process Automation (RPA)?</h2>
              <hr className="divider my-4" />
              <div className="row mt-5">
                <div className="col-sm-8 offset-sm-2">
                  <p>A software technology that can substitute manual work to automate repetitive, rules-based processes. It reduce cost and improve organization's productivity.</p>
                </div>
                <div className="col-sm-6 offset-sm-3 my-auto">
                  <div className="embed-responsive embed-responsive-16by9 shadow">
                    <iframe title="What is Robotic Process Automation?" type="video/mp4" className="embed-responsive-item" src="https://www.youtube.com/embed/tjrkyJYChKY" allowFullScreen controls loading="lazy"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5 mb-5 pt-5 pb-5">
            <div className="row">
              <div className="d-flex parent flex-wrap mb-5">
                <div className="col-md-6 col-sm-12 text-center pb-3">
                  <img className="img-fluid img-50" src="imgs/industries-bldg.svg" alt="Best for Most Industries" data-aos="zoom-in"  data-aos-easing="ease-in-out" />
                </div>
                <div className="col-md-6 col-sm-12 my-auto mx-auto">
                  <h2>Best for most industries</h2>
                  <p>RAX Suite is suitable in the banking, finance, healthcare, insurance, or medical care, and other industries.</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12 my-auto mx-auto mt-5">
                <h2>Wide range of processes</h2>
                <p>RAX Suite is a valuable automation tool for a wide range of processes, including financial operations, procurement, human resource, client management, data entry and validation, or data retrieval.</p>
              </div>
              <div className="col-md-6 col-sm-12 text-center pb-3">
                <img className="img-fluid img-50" src="imgs/range-of-processes.svg" alt="Processes of Work Flow" data-aos="zoom-in" data-aos-easing="ease-in-out" />
              </div>
            </div>
          </div>

          <div className="container-fluid testimonials-container">
            <div className="container mt-5 pt-5 pb-5">
              <div className="mx-auto mt-5">
                <h3 className="sm-title text-uppercase text-white text-center">What Our Customer Say About Us</h3>
                <hr className="divider my-4 pb-3" />
              </div>
              <div className="row pt-3 pb-5">
                <div className="offset-md-2 col-md-2 col-sm-12">
                  <div className="icon-features-div mx-auto">
                    <div className="testimonials">
                      <div className="carousel-info">
                        <img alt="Mr. Eric Sulit, Ricoh Philippines - RAX Suite Client" src="imgs/eric-sulit.jpg" className="pull-left mr-3 img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <p className="mb-0 text-white"><i className="fas fa-quote-left"></i> With RAX automating our billing cycle, we will be able to do more in a shorter amount of time.</p>
                  <span className="testimonials-name">Mr. Eric Sulit</span><br/>
                  <span className="testimonials-post">President, Ricoh Philippines</span>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid gray-bg pt-5">
            <div className="container mb-5 pt-5 pb-5">
              <div className="section-title mb-100 text-center">
                <h2>Blog and Updates</h2>
                <hr className="divider my-4" />
              </div>
              <div className="row equal-height-column">
                <div className="col-md-4 col-sm-12">
                  <div className="equal-column-content single-blog">
                    <img src="imgs/blog-1.jpg" className="img-fluid rounded" alt="Masahiro Hiraishi Monstarlab Omnibus CEO" />
                    <div className="blog-content">
                      <h5 className="h5-news"><a href="https://blog.raxsuite.com/key-benefits-driving-rpa-implementation-in-japan/">Key Benefits Driving RPA Implementation in Japan</a></h5>
                      <p className="p-blog">Masahiro Hiraishi, CEO of Monstarlab Omnibus, noted that RPA implementation is higher in industries like trading, manufacturing, real estate, IT, nursing, and hotel with common and time-consuming processes including data flow between departments, paperwork digitization, and human resources.</p>
                      <a href="https://blog.raxsuite.com/key-benefits-driving-rpa-implementation-in-japan/" rel="nonreferrer">Read More &gt;&gt;</a>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="equal-column-content single-blog">
                    <img src="imgs/blog-2.jpg" className="img-fluid rounded" alt="Gartner RAX Automation Suite RPA" />
                    <div className="blog-content">
                      <h5 className="h5-news"><a href="https://blog.raxsuite.com/gartner-recognizes-rax-automation-suite-in-the-competitive-landscape-rpa-software-report/">Gartner recognizes RAX Automation Suite in the Competitive Landscape: RPA Software Report</a></h5>
                      <p className="p-blog">International research and advisory firm Gartner has recognized RAX Automation Suite in its Robotic Process Automation Software report published in May 2020. RAX Automation Suite has developed robust and powerful RPA platforms that help businesses to unleash the true potential of their workforce.</p>
                      <a href="https://blog.raxsuite.com/gartner-recognizes-rax-automation-suite-in-the-competitive-landscape-rpa-software-report/" rel="nonreferrer">Read More &gt;&gt;</a>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="equal-column-content single-blog">
                    <img src="imgs/blog-3.jpg" className="img-fluid rounded" alt="RICOH Philippines RPA" />
                    <div className="blog-content">
                      <h5 className="h5-news"><a href="https://blog.raxsuite.com/ricoh-philippines-embraces-automation-with-monstar-lab/">Ricoh Philippines Embraces Automation with Monstarlab</a></h5>
                      <p className="p-blog">Technology and Imaging leader Ricoh Philippines embraces robotic process  automation to speed up its work processes and reduce human errors. Through its strategic partnership with Monstar Lab Philippines, Ricoh will utilize the tech’s firm product called RAX EDITOR.</p>
                      <a href="https://blog.raxsuite.com/ricoh-philippines-embraces-automation-with-monstar-lab/" rel="nonreferrer">Read More &gt;&gt;</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5 mb-5 text-center">
                <a href="https://blog.raxsuite.com/" className="btn btn-blog" rel="nonreferrer">View All</a>
              </div>
            </div>
          </div>

          <div className="container pt-5">
            <div className="section-title mb-100 text-center">
              <h2>Featured Videos</h2>
              <hr className="divider my-4" />
            </div>
    
            <div className="row">
              <div className="col-12">
                <section className="row">
                  <div className="col-12 col-md-6 pb-0 pb-md-3 pt-2 pr-md-1 top-indicator">
                    <Carousel activeIndex={ index} onSelect={ (i, e) => setIndex(i) } indicators={ true }>
                      <Carousel.Item>
                        <div className="card border-0 rounded-0 text-light overflow zoom">
                          <div className="position-relative">
                            <div className="ratio_left-cover-1 image-wrapper">
                              <a href="https://www.youtube.com/watch?v=178tYbkAKko" target="_blank" rel="noreferrer">
                                <img className="img-fluid w-100" src="imgs/news/news-8-img-plain.jpg" alt="GovTech Summit Japan 2019" />
                              </a>
                            </div>
                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                              <a href="https://www.youtube.com/watch?v=178tYbkAKko" target="_blank" rel="noreferrer">
                                <h2 className="h3 post-title text-white my-1">RPA Fact or Myth Challenge</h2>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="card border-0 rounded-0 text-light overflow zoom">
                          <div className="position-relative">      
                            <div className="ratio_left-cover-1 image-wrapper">
                              <a href="https://www.youtube.com/watch?v=4uUcKq-AcLo" target="_blank" rel="noreferrer">
                                <img className="img-fluid w-100" src="imgs/news/news-1-img-plain.jpg" alt="RICOH Philippines and RAX Automation Suite Partnership" />
                              </a>
                            </div>
                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                              <a href="https://www.youtube.com/watch?v=4uUcKq-AcLo" target="_blank" rel="noreferrer">
                                <h2 className="h3 post-title text-white my-1">RICOH Philippines and RAX Automation Suite Partnership</h2>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="card border-0 rounded-0 text-light overflow zoom">
                          <div className="position-relative">
                            <div className="ratio_left-cover-1 image-wrapper">
                              <a href="https://www.youtube.com/watch?v=2Hoa9FgbPHc" target="_blank" rel="noreferrer">
                                <img className="img-fluid w-100" src="imgs/news/news-6-img-plain.jpg" alt="RAX Automation Suite joins SOFTCON PH 2019 | PSIA" />
                              </a>
                            </div>
                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">         
                              <a href="https://www.youtube.com/watch?v=2Hoa9FgbPHc" target="_blank" rel="noreferrer">
                                <h2 className="h3 post-title text-white my-1">RAX Automation Suite joins SOFTCON PH 2019 | PSIA</h2>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="card border-0 rounded-0 text-light overflow zoom">
                          <div className="position-relative">
                            <div className="ratio_left-cover-1 image-wrapper">
                              <a href="https://www.youtube.com/watch?v=4pTCZHI9hyo&t=37s" target="_blank" rel="noreferrer">
                                <img className="img-fluid w-100" src="imgs/news/news-7-img-plain.jpg" alt="RAX EDITOR Training Batch" />
                              </a>
                            </div>
                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                              <a href="https://www.youtube.com/watch?v=4pTCZHI9hyo&t=37s" target="_blank" rel="noreferrer">
                                <h2 className="h3 post-title text-white my-1">RAX EDITOR Training Batch 2</h2>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </div>

                  <div className="col-12 col-md-6 pt-2 pl-md-1 mb-3 mb-lg-4">
                    <div className="row">         
                      <div className="col-6 pb-1 pt-0 pr-1">
                        <div className="card border-0 rounded-0 text-white overflow zoom">
                          <div className="position-relative">         
                            <div className="ratio_right-cover-2 image-wrapper">
                              <a href="https://www.youtube.com/watch?v=jrlWdOOTHqI" target="_blank" rel="noreferrer">
                                <img className="img-fluid" src="imgs/news/news-2-img-plain.jpg" alt="The Future of Robotic Process Automation (RPA) in the Philippines" />
                              </a>
                            </div>
                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                              <a href="https://www.youtube.com/watch?v=jrlWdOOTHqI" target="_blank" rel="noreferrer">
                                <h2 className="h5 h5-news text-white my-1">The Future of Robotic Process Automation (RPA) in the Philippines</h2>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                        
    
                      <div className="col-6 pb-1 pl-1 pt-0">
                        <div className="card border-0 rounded-0 text-white overflow zoom">
                          <div className="position-relative">
                            <div className="ratio_right-cover-2 image-wrapper">
                              <a href="https://www.youtube.com/watch?v=8_O-Ic_WTvc" target="_blank" rel="noreferrer">
                                <img className="img-fluid" src="imgs/news/news-3-img-plain.jpg" alt="Innovation Forum 2019 - ANZCHAM" />
                              </a>
                            </div>
                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                              <a href="https://www.youtube.com/watch?v=8_O-Ic_WTvc" target="_blank" rel="noreferrer">
                                  <h2 className="h5 h5-news text-white my-1">RAX Suite featured in Business World Live | One News</h2>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-6 pb-1 pr-1 pt-1">
                        <div className="card border-0 rounded-0 text-white overflow zoom">
                          <div className="position-relative">                              
                            <div className="ratio_right-cover-2 image-wrapper">
                              <a href="https://www.youtube.com/watch?v=J9CLCeS3-Jw" target="_blank" rel="noreferrer">
                                <img className="img-fluid" src="imgs/news/news-4-img-plain.jpg" alt="RPA Training for Beginners using RAX EDITOR" />
                              </a>
                            </div>
                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">
                              <a href="https://www.youtube.com/watch?v=J9CLCeS3-Jw" target="_blank" rel="noreferrer">
                                <h2 className="h5 h5-news text-white my-1">RPA Training for Beginners using RAX EDITOR</h2>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                        
                      <div className="col-6 pb-1 pl-1 pt-1">
                        <div className="card border-0 rounded-0 text-white overflow zoom">
                          <div className="position-relative">                              
                            <div className="ratio_right-cover-2 image-wrapper">
                              <a href="https://www.youtube.com/watch?v=fT-uvLCrLUo" target="_blank" rel="noreferrer">
                                <img className="img-fluid" src="imgs/news/news-5-img-plain.jpg" alt="GovTech Summit Japan 2019" />
                              </a>
                            </div>
                            <div className="position-absolute p-2 p-lg-3 b-0 w-100 bg-shadow">                                
                              <a href="https://www.youtube.com/watch?v=fT-uvLCrLUo" target="_blank" rel="noreferrer">
                                <h2 className="h5 h5-news text-white my-1">GovTech Summit Japan 2019</h2>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </>
    );
};

export default HomePage;