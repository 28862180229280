import React from 'react';

const PrivacyPolicy = () => {
  return (
    <>
      <header className="mt-5">
        <div className="container">
		      <div className="jumbotron privacy-policy-bg">
		  	    <h4 className="font-weight-normal h4-hero">We are committed in protecting the privacy of our users</h4>
		        <h1 className="text-white font-weight-bold h1-hero">Privacy Policy</h1>
		      </div> 
		    </div>
      </header>

      <div className="container">
      	<h3>RAX Automation Suite Privacy Policy</h3>
      	<div className="meta">
          <ul>
              <li>Updated: April 2021</li>
          </ul>
        </div>

        <div className="mt-5">
          <strong>Privacy Policy</strong>
          <p className="privacy-policy-par mt-2">This policy outlines our guidelines regarding the collection, use, and protection of your Customer Data. This policy may change or be updated every so often as deemed necessary and you shall be notified via our website.</p>
          <p className="privacy-policy-par mt-2">By choosing to continue using our website, products, and services, you imply that you have read, understood, and agreed to the collection and use of your Customer Data, especially your Personal Information, in accordance with this Privacy Policy.</p>

          <strong>Collection of Customer Data</strong>
          <p className="privacy-policy-par mt-2">In order to serve you in the best possible way, we may collect your Customer Data which can be your Personal Information or Non-Personal Information:</p>
          <p className="privacy-policy-par mt-2">​Personal Information is information that can be reasonably or directly attributed to the identity of an individual such as name, address, contact number, email address, location etc. We usually gather this information through customer forms on our website or several mediums that enable you to get in touch with us and inform us of your preferred service.</p>
          <p className="privacy-policy-par mt-2">Non-Personal Information, on the other hand, is any information that does not identify you individually, these are statistical and analytical data, and anonymous reports.</p>
          <p className="privacy-policy-par mt-2">We may periodically request for an update of your Personal Information to allow us to better communicate with you and provide excellent customer service. When you use our website, we may also use cookies and similar technologies to get a glimpse of your preferences for a more pleasant experience.</p>

          <strong>Use of Cookies</strong>
          <p className="privacy-policy-par mt-2">Cookies are pieces of text files that allow our website or third-party recognize you. These are used by websites to store website information or preferences, browsing information and anything else that can help the Web browser while accessing Web servers. When you access our website, cookies may be placed in your web browser to help us enable certain functions, obtain analytics, store preferences and allow relevant ad delivery. Third-party cookies may also be used to generate usage statistics and website behavior, deliver ads etc. Partner cookies are anonymous and don’t store personally identifiable information.</p>

          <strong>Use of Customer Data</strong>
          <p className="privacy-policy-par mt-2">We make use acquired customer data in the following manner:</p>
          <ol type="a">
            <li>Primarily to get to know you and your business requirements and get in touch with you</li>
            <li>Provide you with the appropriate products and services, including customer support</li>
            <li>Communicate relevant customer information, service advisories, and updates</li>
            <li>Process information for analytics, statistics, and research that will help us improve our services and overall customer experience.</li>
          </ol>

          <p className="privacy-policy-par mt-2">When required by our Privacy Policy and by Law, and before we use or process your Customer Data for any purpose other than mentioned above, your consent will be sought.</p>
          <p className="privacy-policy-par mt-2">We may also outsource third parties to process Customer Data such as marketing agencies, service partners, or our affiliate offices. Their authorization for the use of Customer Data will only be for the contracted purpose, will only be for a limited time and access and use will only up to the reasonable extent that they need to process. They will also be required to protect Customer Data in keeping with our Privacy Policy.</p>

          <strong>Protection of Customer Data</strong>
          <p className="privacy-policy-par mt-2">We respect and take your privacy seriously and take every effort to secure and protect your Customer Data to ensure confidentiality, prevent loss, theft or unauthorized use. We protect information submitted through our website via a server with firewalls, encryption and security controls.</p>
          <p className="privacy-policy-par mt-2">Only authorized personnel have access to the information and can process your data under strict confidentiality. Access is restricted to employees who need to know your information in order to process or provide the necessary service for you.</p>
          <p className="privacy-policy-par mt-2">Access to your Personal Information is limited to you or your authorized representative. We will never sell your Personal Information to any entity for whatever purpose. We also will never share your information to third-party affiliates without your prior consent.</p>
          <p className="privacy-policy-par mt-2">We keep your Personal Information in our business records for as long as necessary in order for us to fulfill the purpose for which it was gathered. When the time comes for disposing of customer information, we take reasonable measures to make sure it is done in a proper manner and is not accessible to the public.</p>

          <strong>Contact Us</strong>
          <div className="bd-example">
            <address>
              <div className="privacy-policy-par" style={{ lineHeight: '10px !important' }}>
                <p className="mt-2">For any questions or concerns regarding this policy, please use our contact form to get in touch with us.</p>
              </div>
            </address>

            <p className="privacy-policy-par mt-2">&nbsp;</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;