import React from 'react';

const Team = () => {
  return (
    <>
      <div className="container text-center mt-5 mb-5 pt-5 pb-5">
        <h1>Meet the Team</h1>
        <h4>behind RAX Automation Suite</h4>
        <hr className="my-4" />
      </div>

      <div className="team-members" style={{ paddingBottom: '0px!important' }}>
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-allantan.jpg" className="img-fluid team-img rounded" alt="Allan Tan" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Allan</strong>   
                    <span>Product Expert</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-masahiro.jpg" className="img-fluid team-img rounded" alt="Masahiro Hiraishi" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Masahiro</strong>   
                    <span>Manager (RPA Division)</span>
                    <span className="country">Japan</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-marksy.jpg" className="img-fluid team-img rounded" alt="Mark Albert Sy" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Maki</strong>   
                    <span>Product Manager</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-chrislee.jpg" className="img-fluid team-img rounded" alt="Christopher Lee" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Chris</strong>   
                    <span>Design and Product Marketing</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <div className="single-team mt-2 mb-2">
              <img src="imgs/team-tine.jpg" className="img-fluid team-img rounded" alt="Ma. Kristina Piedad" />
              <div className="team-hover">
                <h4 className="h3 team-nameplate"><strong>Tine</strong>   
                  <span>Sales Executive</span>
                  <span className="country">Philippines</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="single-team mt-2 mb-2">
              <img src="imgs/team-teruo.jpg" className="img-fluid team-img rounded" alt="Teruo Oshida" />
              <div className="team-hover">
                <h4 className="h3 team-nameplate"><strong>Teruo</strong>   
                  <span>Technologist</span>
                  <span className="country">Japan</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="single-team mt-2 mb-2">
              <img src="imgs/team-genki.jpg" className="img-fluid team-img rounded" alt="Genki Tajima" />
              <div className="team-hover">
                <h4 className="h3 team-nameplate"><strong>Genki</strong>   
                  <span>Consultant</span>
                  <span className="country">Japan</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="single-team mt-2 mb-2">
              <img src="imgs/team-princessvaldez.jpg" className="img-fluid team-img rounded" alt="Princess Valdez" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Princess</strong>   
                    <span>Team Lead</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="team-members">
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-damianfadri.jpg" className="img-fluid team-img rounded" alt="Damian Fadri" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Damian</strong>   
                    <span>Developer</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-kathvillegas.jpg" className="img-fluid team-img rounded" alt="Katherine Villegas" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Kath</strong>   
                    <span>Developer</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                  <img src="imgs/team-patrick.jpg" className="img-fluid team-img rounded" alt="Patrick Victoria" />
                  <div className="team-hover">
                    <h4 className="h3 team-nameplate"><strong>Patrick</strong>   
                      <span>Developer</span>
                      <span className="country">Philippines</span>
                    </h4>
                  </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-russ.jpg" className="img-fluid team-img rounded" alt="Russ Delos Santos" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Russ</strong>   
                    <span>Developer</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-harley.jpg" className="img-fluid team-img rounded" alt="Harley Regua" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Harley</strong>   
                    <span>Developer</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-neil.jpg" className="img-fluid team-img rounded" alt="Rean Luces" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Rean</strong>   
                    <span>Developer</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-zai.jpg" className="img-fluid team-img rounded" alt="Geocel Villareal" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Zai</strong>   
                    <span>Software Tester</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="single-team mt-2 mb-2">
                <img src="imgs/team-florie.jpg" className="img-fluid team-img rounded" alt="Florie Del Carmen" />
                <div className="team-hover">
                  <h4 className="h3 team-nameplate"><strong>Florie</strong>   
                    <span>UI/UX Designer</span>
                    <span className="country">Philippines</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid social-media-container">
        <div className="row pt-5 pb-5" style={{ height: '50vh' }}>
          <div className="col-md-8 offset-md-2 mt-5 pt-5 pb-5 text-center team-social-media-icons my-auto social-media-border">
            <h5 className="h5-social-media">Let's Stay Connected</h5>
              <ul className="list-unstyled list-inline socials text-center mt-3">
                <li className="list-inline-item-social-team"><a href="https://www.facebook.com/raxautomationsuite/" target="_blank" className="social-icons" rel="noreferrer"> <i className="fab fa-facebook-f"></i> </a></li>
                <li className="list-inline-item list-inline-item-social-team"><a href="https://twitter.com/raxsuite" target="_blank" className="social-icons" rel="noreferrer"> <i className="fab fa-twitter"></i> </a></li>
                <li className="list-inline-item list-inline-item-social-team"><a href="https://www.linkedin.com/company/raxsuite/" target="_blank" className="social-icons" rel="noreferrer"> <i className="fab fa-linkedin-in"></i> </a></li>
                <li className="list-inline-item list-inline-item-social-team"><a href="https://medium.com/@raxsuite" target="_blank" className="social-icons" rel="noreferrer"> <i className="fab fa-medium"></i> </a></li>
                <li className="list-inline-item list-inline-item-social-team"><a href="https://www.youtube.com/channel/UCL-W5wNyTY9bSa2WUpOAx0w" target="_blank" className="social-icons" rel="noreferrer"> <i className="fab fa-youtube"></i> </a></li>
              </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;