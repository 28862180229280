import React from 'react';

const FreeDownloadFormSuccess = () => {
  return (
    <>
      <h4>Congratulations!</h4>
      <p>Thank you for downloading RAX EDITOR's free version. Give it a moment and your download link will appear in your inbox.</p>
      <p>In some rare moment that you didn't receive the email right away, kindly check you spam folder.</p>
    </>
  );
};

export default FreeDownloadFormSuccess;