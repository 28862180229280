import { useState } from 'react';

const getStorageValue = (k) => {
    return localStorage.getItem(k);
};

const setStorageValue = (k, v) => {
    localStorage.setItem(k, v);
};

const useStorage = (key) => {
    const [ stateValue, setStateValue ] = useState(getStorageValue(key));

    const setValue = (v) => {
        setStorageValue(key, v);
        setStateValue(v);
    };

    return [ stateValue, setValue ];
};

export default useStorage;