import React from 'react';
import { Link } from '@reach/router';

const NotFound = () => {
  return (
    <>
      <div className="">
        <div className="container-fluid container-404">
          <div className="row justify-content-center">
            <div className="col-sm-12">
              <div className="text-center d-block mt-5">
                <h1 className="h1-404 d-inline">404</h1>
                <h3 className="h3-404 d-inline">Error</h3>
                <h4 className="h4-404">Page not found</h4>
                <p className="p-404 pl-5 pr-5 pb-3">We can't find the page you're looking for. Click the button to go back to our home page.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <Link className="btn btn-primary btn-404 d-block mb-2" to="/">Go to Home Page</Link>
          </div>
          <div className="row justify-content-center pt-5 pb-5">
            <img src="imgs/404-error-msg-img.svg" className="img-fluid img-404" alt="404 Not Found" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;